import Button from "../../ui/Button";
import Checkbox from "../../ui/Checkbox";
import Icon from "../../ui/Icon";
import Input from "../../ui/Input";
import Title from "../../ui/Title";
import { AuthorizationContext } from "../../../bootstrap/contexts/AuthorizationContext";
import settings from "../../../bootstrap/config/settings";
import destraLink from "../../../helpers/destraLink";
import useWindowSize from "../../../helpers/windowSize";
import returnCookieValue from "../../../helpers/returnCookieValue";
import yandexMetrika from "../../../helpers/yandexMetrika";
import * as AuthenticationService from "../../../bootstrap/services/authService";
import { createOrder } from "../../../bootstrap/services/orderService";
import { authSMS, loginSMS } from "../../../bootstrap/services/sms.auth.service";
import { updateUsersData, updateUsersPhone } from "../../../bootstrap/services/usersService";
import { useContext, useEffect, useState } from "react";
import LawyerCap from "./LawyerCap";
import Modal from "../../ui/Modal";
import AdvantagesList from "./AdvantagesList";
import AuthPhoneTimer from "./AuthPhoneTimer";
import styles from "./LandingForm.module.scss";
import Toggle from "../../ui/Toggle";
import registerAnalyticsEvent from "../../../helpers/registerAnalyticsEvent";
import sideServices from "./sideServices.json";

const list = [
  {
    id: "auth-advantage-1",
    title: "Убедитесь, что телефон в сети и на счету положительный баланс"
  },
  { id: "auth-advantage-2", title: "Попробуйте перезагрузить телефон" }
];

const LandingForm = ({
  instructionId,
  callback,
  template = "default",
  showButton = true,
  titleBtn = "Оставить заявку"
}) => {
  const { user, access_token, login } = useContext(AuthorizationContext);
  const { width } = useWindowSize();
  const [state, setState] = useState({
    name: user?.name ?? "",
    phone: user?.phone ?? "",
    commentary: "",
    error: "",
    authToken: "",
    timer: 0,
    modalSMS: false
  });

  const [allowedCalls, setAllowedCalls] = useState(true);
  const [codeValue, setCodeValue] = useState("");
  const [showOrder, setShowOrder] = useState(false);
  const [orderLink, setOrderLink] = useState(null);

  useEffect(() => {
    if (access_token && access_token !== undefined) settings.access_token = access_token;
    if (user) {
      setState({ ...state, phone: user?.phone ?? "", name: user?.name ?? "" });
    }
  }, [user]);

  const [isCheckboxError, setIsCheckboxError] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [callDate, setCallDate] = useState([]);

  const [isNewYearHolidays, setIsNewYearHolidays] = useState(false);


  useEffect(() => {
    const todayMonth = new Date().getMonth();
    const todayDay = new Date().getDate();
    const isNYHolidays = (todayMonth == 0 && todayDay < 5) || (todayMonth == 11 && todayDay > 30);
    setIsNewYearHolidays(isNYHolidays);
  }, []);

  const handleUpdateUserName = () => {
    if (state.name !== user.name && state.name) {
      return updateUsersData(access_token, { name: state.name });
    }
  };

  const handleCheckboxChange = () => setIsTermsChecked(!isTermsChecked);

  const isPhoneValid = state.phone && !state.phone.includes("_");

  const _orderData = (userLoaded) => {
    const date = new Date();
    const utc = (Number(date.getTimezoneOffset()) * -1) / 60;
    const dateFormBankrupcty = {
      userName: state.name ?? ""
    };
    const uri = window.location.pathname;
    return {
      _user: userLoaded?._id ?? user?._id,
      status: "0",
      name: state?.name,
      utm: [
        {
          label: "utm_source",
          value: userLoaded?.source ?? user?.source
        },
        {
          label: "utm_medium",
          value: userLoaded?.medium ?? user?.medium
        },
        {
          label: "utm_campaign",
          value: userLoaded?.campaign ?? user?.campaign
        },
        {
          label: "form",
          value: "blog_widget_form"
        },
        {
          label: "uri",
          value: uri,
        },
      ],
      instruction: {
        slug: instructionId,
      },
      notes: [
        {
          note: `${dateFormBankrupcty.userName ? `Имя: ${dateFormBankrupcty.userName}\n` : ""}${dateFormBankrupcty.dayCall ? `Дата звонка: ${dateFormBankrupcty.dayCall}\n` : ""
            }${dateFormBankrupcty.timeCall ? `Время звонка: ${dateFormBankrupcty.timeCall}\n` : ""}`
        }
      ],
      phone: state.phone,
      title: "Консультация юриста",
      timezone: String(utc)
    };
  };

  const handleCreateOrder = async (access_token = settings.access_token) => {
    try {
      const userLoaded = await AuthenticationService.getUser(access_token);
      const orderBody = _orderData(userLoaded);
      createOrder(access_token, orderBody).then((res) => {
        if (window.gtag) {
          window.gtag("event", "click", { event_category: "Button", event_label: "Order_create" });
        }
        yandexMetrika("reachGoal", "move_to_create_order");
        yandexMetrika("reachGoal", "complete_order");
        yandexMetrika("reachGoal", "blog_widget_form_send");
        registerAnalyticsEvent({ event: 'bucket', templateId: res._id, title: `Заказ: ${res.name}` })
        if (res?.event === "USER_CREATED") {
          if (window?.gtag) {
            window.gtag("event", "registration_complete", {
              event_label: "registration_complete"
            });
          }
          yandexMetrika("reachGoal", "registration_complete");
        }
        setState({
          name: user?.name ?? "",
          phone: user?.phone ?? "",
          commentary: "",
          error: "",
          authToken: "",
          timer: 0,
          modalSMS: false
        });
        setShowOrder(true);
        setOrderLink(`https://destralegal.ru/account/order/${res._id}?order=created`);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getCode = async () => {
    try {
      if (!isPhoneValid) {
        setState({ ...state, error: "Номер телефона введен не верно" });
        if (!isTermsChecked) setIsCheckboxError(true);
        setTimeout(() => {
          setState((ps) => ({ ...ps, error: "" }));
          setIsCheckboxError(false);
        }, 3000);
        return;
      }
      if (!isTermsChecked) {
        setIsCheckboxError(true);
        setTimeout(() => setIsCheckboxError(false), 3000);
        return;
      }
      if (user?._id && isPhoneValid && state.phone && isTermsChecked) {
        if (state.phone !== user.phone) {
          updateUsersPhone(access_token, state.phone);
        }
        await handleUpdateUserName();
        await updateUsersPhone(access_token, state.phone);
        handleCreateOrder(access_token);
        return;
      }
      if (isPhoneValid && isTermsChecked) {
        const res = await loginSMS({
          phone: state.phone ? state.phone : user.phone,
          captchaToken: "blog",
          captchaType: "blog",
        });
        setState({ ...state, authToken: res.token, timer: 59 });
        if (callback) callback("sms");
        if (!res && !res.token) {
          setState({ ...state, error: "Произошла ошибка. Обратитесь в чат поддержки" });
          setTimeout(() => setState({ ...state, error: "" }), 3000);
        }
        return;
      }
      if (isPhoneValid && !user.phone && isTermsChecked) {
        await handleUpdateUserName();
        await updateUsersData(access_token, { phone: state.phone });
        handleCreateOrder(access_token);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onMoveToOrder = () => {
    if (orderLink) window.location.href = orderLink;
    setShowOrder(false);
    setOrderLink(null);
  };


  const changePhone = () => {
    if (callback) callback(false);
    setState({ ...state, phone: "", authToken: "" });
  };

  useEffect(() => {
    if (isPhoneValid && callback) callback(true);
    if (!isPhoneValid && callback) callback(false);
  }, [state.phone]);

  const handleInputChange = (e) => {
    const result = e.target.value;
    setCodeValue(result);
    if (!result.includes("_") && result.length === 4) {
      authSMS({
        token: state.authToken,
        code: result,
        utm_source: returnCookieValue("utm_source"),
        utm_medium: returnCookieValue("utm_medium"),
        utm_campaign: returnCookieValue("utm_campaign"),
        admitad_uid: returnCookieValue("admitad_uid")
      })
        .then((res) => {
          const isRegistrationSuccessful = res.refresh_token && res.access_token;
          const isCodeNotExist = res && res.message === "Auth code do not exist";
          const notifyRequestNewCode = () => {
            setState((ps) => ({ ...ps, error: "Запросите новый код", timer: 0 }));
            setTimeout(() => setState((ps) => ({ ...ps, error: "" })), 3000);
          };
          const notifyCodeIncorrect = () => {
            setState((ps) => ({ ...ps, error: "Неправильный код", timer: 0, code: "" }));
            setTimeout(() => setState((ps) => ({ ...ps, error: "" })), 3000);
          };
          if (!isRegistrationSuccessful && isCodeNotExist) {
            return notifyRequestNewCode();
          }
          if (!isRegistrationSuccessful && !isCodeNotExist) {
            return notifyCodeIncorrect();
          }
          if (isRegistrationSuccessful) {
            settings.access_token = res.access_token;
            if (res?.event === "USER_CREATED") {
              if (window?.gtag) {
                window.gtag("event", "registration_complete", {
                  event_label: "registration_complete"
                });
              }
              yandexMetrika("reachGoal", "registration_complete");
            }
            handleCreateOrder(res.access_token);
            login(res);
          }
          if (!isRegistrationSuccessful && isCodeNotExist) {
            return notifyRequestNewCode();
          }
          if (!isRegistrationSuccessful && !isCodeNotExist) {
            return notifyCodeIncorrect();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className={styles.formWrapper}>
      <div
        className={`${styles[`bankruptcy_form__${template}`]} ${styles.authPhone__newView__main}`}
        id='form'
      >
        <div className={styles.capWrapper}>
          <LawyerCap />
        </div>
        {state.authToken === "" ? (
          <div
            className={`${styles.authPhone__newView__section} ${styles.authPhone__newView__section_phone}`}
          >
            <div className={styles.documentModalForm__sectionTitle}>
              Запишитесь {width <= 1024 ? <br /> : undefined} на консультацию юриста
            </div>
            <div className={styles.fieldName}>
              <Input
                size='ultra'
                defaultValue={state?.name}
                onChange={(e) => setState({ ...state, name: e.target.value })}
                placeholder='Имя'
                type='text'
              />
            </div>
            <div>
              {showButton || state.phone.replace(/\D+/g, "").length === 11 ? (
                <>
                  <Input
                    size='ultra'
                    defaultValue={state.phone}
                    error={state.error}
                    onChange={(e) => setState({ ...state, phone: e.target.value })}
                    placeholder='Телефон'
                    mask='+7 (999) 999-99-99'
                    required
                  />
                </>
              ) : null}
            </div>
            <div className={styles.formCallsSection}>
              <div className={styles.formCallsToggle}>
                <Toggle
                  defaultValue={allowedCalls}
                  onChange={(id, value) => setAllowedCalls(value)}
                />
              </div>
              <div className={styles.formCallsText}>
                Не получать рекламные звонки
              </div>
            </div>
            <>
              <div className={`${styles[`authPhone__newView__phone__button__${template}`]}`}>
                <Button onClick={getCode}>
                  <div className={titleBtn ? styles.bank__any__form : styles.bank__main__form}>
                    {titleBtn || "Продолжить"}
                  </div>
                </Button>
              </div>
              <div className={`${styles[`form__terms__${template}`]}`}>
                <div>
                  <Checkbox
                    isWithBorder={false}
                    checked={isTermsChecked}
                    handleCheck={handleCheckboxChange}
                    error={isCheckboxError}
                  />
                </div>
                <div className={`${styles.form__terms__description} ${isCheckboxError ? styles.checkboxError : ""}`}>
                  Принимаю условия&nbsp;
                  <a href={`https://destralegal.ru/privacy.pdf`} className={styles.form__terms__description__link}>
                    Политики конфиденциальности&nbsp;
                  </a>
                  {" "}
                  и&nbsp;
                  <a href={`https://destralegal.ru/offer.pdf`} className={styles.form__terms__description__link}>
                    Пользовательского соглашения.
                  </a>
                </div>
              </div>
            </>
          </div>
        ) : (
          <div className={`${styles.auth_phone__section} ${styles.auth_phone__section_actions}`}>
            <div className={styles.documentModalForm__sectionTitle}>
              Запишитесь {width > 1024 ? <br /> : undefined}на консультацию юриста
            </div>
            <div className={styles.auth_phone__actions__description}>
              <span className={styles.auth_phone__actions__description_text}>
                Введите код, который мы отправили на номер <br />
                <span className={styles.auth_phone__actions__description_number}>{state.phone}</span>
                <span
                  onClick={changePhone}
                  className={styles.auth_phone__actions__description_button}
                >
                  Изменить
                </span>
              </span>
            </div>
            <div className={styles.auth_phone__actions__input}>
              <Input
                size='ultra'
                defaultValue={codeValue}
                mask='9999'
                error={state.error}
                onChange={handleInputChange}
                placeholder='Код'
                required
              />
            </div>
            <AuthPhoneTimer handleClick={getCode} time={state.timer} />
            <div
              onClick={() => setState({ ...state, modalSMS: true })}
              className={styles.auth_phone__actions__sms}
            >
              Не приходит СМС?
            </div>
          </div>
        )}
        <div className={`${styles.auth_phone__section} ${styles.auth_phone__section_modal}`}>
          <Modal
            template='sms-not-get'
            isOpen={state.modalSMS}
            closeButton
            onClose={() => setState({ ...state, modalSMS: false })}
          >
            <div className={styles.authPhone__modal}>
              <div
                className={`${styles.authPhone__modal__section} ${styles.authPhone__modal__sectionTitle}`}
              >
                <div className={styles.authPhone__modal__title}>Не приходит СМС?</div>
              </div>
              <div
                className={`${styles.authPhone__modal__section} ${styles.authPhone__modal__sectionText}`}
              >
                <div className={styles.authPhone__modal__text}>
                  Пожалуйста, проверьте, что ваш телефон доступен.
                </div>
              </div>
              <div
                className={`${styles.authPhone__modal__section} ${styles.authPhone__modal__sectionList}`}
              >
                <AdvantagesList array={list} />
              </div>
              <div
                className={`${styles.authPhone__modal__section} ${styles.authPhone__modal__sectionText}`}
              >
                <div className={styles.authPhone__modal__text}>
                  Если проблема сохраняется, пожалуйста, сообщите нам в чате
                </div>
              </div>
              <div
                className={`${styles.authPhone__modal__section} ${styles.authPhone__modal__sectionButton}`}
              >
                <Button onClick={() => setState({ ...state, modalSMS: false })}>Закрыть</Button>
              </div>
            </div>
          </Modal>
        </div>
        <div className={`${styles.auth_phone__section} ${styles.auth_phone__section_modal}`}>
          <Modal template='medium' isOpen={showOrder} closeButton onClose={() => setShowOrder(false)}>
            <div className={styles.createOrderModal}>
              <div className={styles.iconContainer}>
                <Icon name='green-tick' />
              </div>
              <Title type='h2' className={styles.titleClass}>
                Ваша заявка принята
              </Title>

              {isNewYearHolidays ?
                <>
                  <span className={styles.description}>
                    Юрист позвонит вам с телефона <bold>+7&nbsp;(495)&nbsp;120-09-77</bold> и отправит
                    сообщение в чате в рабочее время.
                  </span>
                  <p className={`${styles.description} ${styles.descriptionBold}`}>
                    График работы юристов
                  </p>
                  <span className={styles.descriptionSchedule}>
                    С 31 декабря по 3 января: Выходной
                  </span>
                  <span className={styles.descriptionSchedule}>
                    С 4 по 8 января: c 11 до 21 МСК
                  </span>
                  <span className={styles.descriptionSchedule}>
                    С 9 января в обычном режиме
                  </span>
                </>
                :
                <>
                  <span className={styles.description}>
                    Юрист позвонит вам с телефона <bold>+7&nbsp;(800)&nbsp;775-78-24</bold> и отправит
                    сообщение в чате.
                  </span>
                </>
              }
              <div className={styles.buttonContainer}>
                <Button onClick={onMoveToOrder}>Перейти к заказу</Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
      <div className={styles.sideServicesBar}>
        {sideServices.map((service) => (
          <div key={service.id} className={styles.serviceSection}>
            <div className={styles.serviceIconSection}>
              <div className={styles.serviceIcon}>
                <Icon name={service.icon} />
              </div>
            </div>
            <div className={styles.serviceTitle}>
              {service.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LandingForm;
